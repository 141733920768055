import { Provider as PaperProvider } from "react-native-paper";
import React, {
 createContext,
 useCallback,
 useContext,
 useEffect,
 useLayoutEffect,
 useState,
} from "react";
import { getFocusedRouteNameFromRoute, NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { createMaterialBottomTabNavigator } from "@react-navigation/material-bottom-tabs";
import ListView from "./views/ListView";
import EditView from "./views/EditView";
import LoginView from "./views/LoginView";
import useDatabase, { FirebaseDatabase } from "./databases/FirebaseDatabase";
import ToastHandler from "./components/ToastHandler";
import UsersView from "./views/UsersView";
import { LogBox, Platform, useColorScheme } from "react-native";
import SplashScreen from "./views/SplashScreen";
import InfoView from "./views/InfoView";
import DetailsView from "./views/DetailsView";
// import PdfView from "./views/PdfView";
import { Notifications } from "./hooks/useNotifications";
import CustomListView from "./views/CustomListView";
import EditCustomListView from "./views/EditCustomListView";
import ConfirmationHandler from "./components/ConfirmationHandler";
import FullScreenImage from "./components/FullScreenImage";
import PdfView from "./views/PdfView";
import { UserFab } from "./components/UserFab";
import { FileManager } from "./hooks/useFileManager";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { FileDownloader } from "./hooks/useFileDownloader";
import baseTheme from "./services/baseTheme";
import { StatusBar } from "expo-status-bar";
import ArchivedView from "./views/ArchivedView";
import VersionController from "./components/VersionController";

LogBox.ignoreLogs(["Setting a timer", "AsyncStorage has been extracted", "Require cycle:"]);

// extend the theme
const Stack = createNativeStackNavigator();
const Tab = createMaterialBottomTabNavigator();

// Ajustes manuais no css da barra de navegação do navegador
try {
 if (Platform.OS === "web") {
  window.document.body.style.overflow = "hidden";
  const css = `
  /* width */
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #ffffff00; 
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
  `;
  const style = window.document.createElement("style");
  if (window.document.head) window.document.head.appendChild(style);
  style.appendChild(window.document.createTextNode(css));
 }
} catch (e) {
 console.log("NO BODY");
}

const AppContext = createContext({});

export default function App() {
 const colorScheme = useColorScheme();
 const [tabVisible, setTabVisible] = useState(true);

 const [isDark, setIsDark] = useState(colorScheme === "dark");
 const [lightTheme, setLightTheme] = useState({ ...baseTheme.lightTheme });
 const [darkTheme, setDarkTheme] = useState({ ...baseTheme.darkTheme });

 const updateTheme = useCallback((props, type = "light") => {
  if (type === "light") setLightTheme((current) => ({ ...current, ...props }));
  else setDarkTheme((current) => ({ ...current, ...props }));
 }, []);

 const toggleTheme = useCallback(() => {
  const newVal = !isDark;
  setIsDark(newVal);
  AsyncStorage.setItem("theme", newVal ? "dark" : "light");
 }, [isDark]);

 useEffect(() => {
  AsyncStorage.getItem("theme", (e, res) => {
   if (e) {
    console.log(e);
    return;
   }
   setIsDark(res === "dark");
  });
 });

 return (
  <PaperProvider theme={isDark ? darkTheme : lightTheme}>
   <AppContext.Provider value={{ tabVisible, setTabVisible, updateTheme, toggleTheme, isDark }}>
    <StatusBar style={isDark ? "light" : "dark"} />
    <FirebaseDatabase>
     <FileDownloader>
      <FileManager>
       <Notifications>
        <UserFab>
         <ConfirmationHandler />
         <VersionController />
         <FullScreenImage />
         <ToastHandler />
         <NavigationContainer>
          <Routes />
         </NavigationContainer>
        </UserFab>
       </Notifications>
      </FileManager>
     </FileDownloader>
    </FirebaseDatabase>
   </AppContext.Provider>
  </PaperProvider>
 );
}

export const useApp = () => {
 return useContext(AppContext);
};

function getIconFromRoute(route) {
 switch (route) {
  case "Home":
   return "format-list-bulleted-square";
  case "Usuários":
   return "account-supervisor";
  case "Informações":
   return "bulletin-board";
  case "Listas":
   return "playlist-edit";
  default:
   return "cog";
 }
}

function Routes() {
 const { tabVisible } = useApp();
 const { user, showSplashScreen, isAdmin, systemConfig, isClientDisabled, isTrial } = useDatabase();
 if (user)
  return (
   <Tab.Navigator
    initialRouteName="Home"
    barStyle={{
     display: tabVisible ? null : "none",
    }}
    screenOptions={({ route }) => ({
     tabBarIcon: getIconFromRoute(route.name),
    })}>
    <Tab.Screen
     name="Home"
     component={Home}
     options={{ headerShown: false, title: systemConfig.list.listTitle || "Lista Principal" }}
    />
    <Tab.Screen
     name="Informações"
     component={InfoView}
     options={{ headerShown: false, title: "Anotações" }}
    />
    {isAdmin && (
     <>
      {!isTrial && <Tab.Screen name="Usuários" component={UsersView} />}
      {systemConfig.customLists?.length > 0 && (
       <Tab.Screen name="Listas" component={CustomLists} options={{ headerShown: false }} />
      )}
     </>
    )}
   </Tab.Navigator>
  );
 else if (showSplashScreen || isClientDisabled)
  return (
   <Stack.Navigator initialRouteName="Splash">
    <Stack.Screen options={{ headerShown: false }} name="Splash" component={SplashScreen} />
   </Stack.Navigator>
  );
 else
  return (
   <Stack.Navigator initialRouteName="Login">
    <Stack.Screen options={{ headerShown: false }} name="Login" component={LoginView} />
   </Stack.Navigator>
  );
}

function Home({ navigation, route }) {
 const { systemConfig } = useDatabase();

 useLayoutEffect(() => {
  const routeName = getFocusedRouteNameFromRoute(route);
  if (routeName === "PDF") navigation.setOptions({ tabBarStyle: { display: "none" } });
 }, [navigation, route]);

 return (
  <Stack.Navigator initialRouteName="Lista" screenOptions={{ headerShown: false }}>
   <Stack.Screen
    name="Lista"
    component={ListView}
    options={{ title: systemConfig.list.listTitle || "Lista Principal" }}
   />
   <Stack.Screen name="Detalhes" component={DetailsView} />
   <Stack.Screen name="Editar" component={EditView} />
   <Stack.Screen name="PDF" component={PdfView} options={{ title: "Visualizar PDF" }} />
   <Stack.Screen name="Arquivados" component={ArchivedView} />
  </Stack.Navigator>
 );
}

function CustomLists() {
 return (
  <Stack.Navigator initialRouteName="Minhas Listas" screenOptions={{ headerShown: false }}>
   <Stack.Screen name="Minhas Listas" component={CustomListView} />
   <Stack.Screen name="Editar Lista" component={EditCustomListView} />
  </Stack.Navigator>
 );
}
