import React, { useCallback, useEffect, useRef, useState } from "react";
import useDatabase from "../databases/FirebaseDatabase";
import { Appbar, Button, IconButton, MD3Colors, TextInput, useTheme } from "react-native-paper";
import { ScrollView, View } from "react-native";
import useFab from "../components/UserFab";
import { useFocusEffect } from "@react-navigation/native";
import { cloneDeep } from "lodash";

export default function EditCustomListView({ navigation, route }) {
 const theme = useTheme();
 const { systemConfig, customLists, updateCustomList } = useDatabase();
 const { index } = route.params;
 const listData = systemConfig.customLists[index];

 // código para mostrar fab na rota
 const { hideFab } = useFab();
 useFocusEffect(
  useCallback(() => {
   const unsubscribe = navigation.addListener("focus", () => {
    hideFab();
   });
   return unsubscribe;
  }, [navigation, hideFab])
 );

 // Guardaremos os dados em uma ref, para melhorar a performance, porém precisaremos
 // recarregar a tela manualmente quando necessário usando refreshScreen
 const data = useRef([]);

 // Controlador para recarregar a tela
 const [refreshIndex, setRefresh] = useState(0);
 const refreshScreen = useCallback(() => {
  setRefresh((i) => i + 1);
 }, []);

 const [saving, setSaving] = useState(false);

 const addItem = useCallback(() => {
  data.current.push("");
  refreshScreen();
 }, [refreshScreen]);

 const removeItem = useCallback(
  (index) => {
   data.current.splice(index, 1);
   refreshScreen();
  },
  [refreshScreen]
 );

 const updateItem = useCallback(
  (value, index, refresh = false) => {
   data.current[index] = value;
   if (refresh) refreshScreen();
  },
  [refreshScreen]
 );

 const saveData = useCallback(async () => {
  data.current = data.current.filter((val) => val);
  setSaving(true);
  try {
   await updateCustomList(listData.alias, data.current);
   setSaving(false);
   navigation.goBack();
  } catch (e) {
   throw e;
  }
 }, [listData, navigation, updateCustomList]);

 useEffect(() => {
  if (customLists[listData.alias]) {
   data.current = cloneDeep(customLists[listData.alias]);
   refreshScreen();
  }
 }, [customLists, listData, refreshScreen]);

 if (!data.current) return null;
 return (
  <>
   <Appbar.Header>
    <Appbar.BackAction
     onPress={() => {
      navigation.goBack();
     }}
    />
    <Appbar.Content title="Editar Lista" />
    <Appbar.Action disabled={saving} icon="content-save" onPress={saveData} />
   </Appbar.Header>
   <ScrollView style={{ backgroundColor: theme.colors.background }} key={refreshIndex}>
    {data.current.map((item, i) => (
     <View
      key={i}
      style={{
       display: "flex",
       flexDirection: "row",
       alignItems: "center",
       paddingVertical: 10,
       paddingHorizontal: 20,
       maxWidth: 1000,
       marginHorizontal: "auto",
       width: "100%",
      }}>
      <TextInput
       // dense
       style={{ width: "100%", flex: 1 }}
       placeholder="Nome"
       onChangeText={(name) => {
        updateItem(name, i);
       }}
       defaultValue={item}
      />
      <IconButton
       style={{ marginLeft: 5 }}
       icon="delete-outline"
       iconColor={MD3Colors.error50}
       size={20}
       onPress={() => removeItem(i)}
      />
     </View>
    ))}
    <Button
     mode="contained"
     style={{
      marginVertical: 20,
      maxWidth: 1000,
      marginHorizontal: "auto",
      width: "90%",
     }}
     onPress={() => addItem()}
     icon="playlist-plus">
     Novo Item
    </Button>
   </ScrollView>
  </>
 );
}
