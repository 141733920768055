import React, { useCallback, useEffect, useMemo, useState } from "react";
import useDatabase from "../databases/FirebaseDatabase";
import { FlatList, StyleSheet, View } from "react-native";
import { useFocusEffect } from "@react-navigation/native";
import {
 Text,
 Searchbar,
 useTheme,
 Menu,
 Appbar,
 TouchableRipple,
 ActivityIndicator,
} from "react-native-paper";
import useFab from "../components/UserFab";
import FilterModal from "../components/FilterModal";
import DateFilterModal from "../components/DateFilterModal";
import { isAfter, isBefore, isEqual, isValid } from "date-fns";

let cached = null;
let cachedSearchableKeys = null;
let cachedDateKey = null;

const styles = StyleSheet.create({
 hstack: {
  display: "flex",
  flexDirection: "row",
 },
 vstack: {
  display: "flex",
  flexDirection: "column",
 },
});

export default function ListView({ navigation }) {
 const theme = useTheme();
 const { data, systemConfig, isAdmin, archiveItems, getLocalStorage, saveLocalStorage, isTrial } =
  useDatabase();
 const [availableFilters, setAvailableFilters] = useState(null);
 const [dateFilterKey, setDateFilterKey] = useState(null);

 const [filterBy, setFilterBy] = useState({ key: null, value: "_all_" });
 const [filterByDate, setFilterByDate] = useState({ fromDate: null, toDate: null });

 const [availableSearchable, setAvailableSearchable] = useState([]);
 const [searchBy, setSearchBy] = useState("");

 const [parsedData, setParsedData] = useState([]);
 const [isEmpty, setIsEmpty] = useState(false);

 const [filterModal, setFilterModal] = useState(false);

 const [page, setPage] = useState(1);
 const [pagedData, setPagedData] = useState([]);

 // código para mostrar fab na rota
 const { showFab, addOption, removeOption } = useFab();

 useEffect(() => {
  const unsubscribe = navigation.addListener("focus", () => {
   showFab();
  });
  return unsubscribe;
 }, [navigation, showFab]);

 useFocusEffect(
  useCallback(() => {
   if (isAdmin)
    addOption("archive-all", "Arquivar Lista", "archive-plus", () => {
     window.confirmationModal({
      title: "Arquivar lista atual?",
      description: (
       <>
        Essa ação irá pegar todos os itens da lista atual (e que atendam o seu filtro atual) e irá
        arquivá-los.{"\n"}
        {"\n"} Ao arquivar um item, ele não aparecerá mais em sua lista principal. Você ainda poderá
        acessar os itens arquivados através o menu. {"\n"}
        {"\n"}É recomendado arquivar seus itens quando sua lista atual estiver muito grande (1000
        itens ou mais).
       </>
      ),
      positiveLabel: "Arquivar",
      positiveCallback: async () => {
       if (parsedData.length) {
        archiveItems(parsedData);
       }
      },
     });
    });

   if (!isTrial)
    addOption("view-archived", "Ver Arquivados", "archive", () => {
     navigation.navigate("Arquivados", {});
    });

   return () => {
    removeOption("archive-all");
    removeOption("view-archived");
   };
  }, [addOption, isAdmin, removeOption, parsedData, archiveItems, navigation, isTrial])
 );

 useEffect(() => {
  async function parse() {
   let parsed = [];

   const keys = Object.keys(data);

   if (!keys.length) setIsEmpty(true);
   else setIsEmpty(false);

   // Adicinamos os itens que atenderem o filtro
   keys.forEach((key) => {
    if (filterBy.value !== "_all_" && data[key][filterBy.key] !== filterBy.value) return;
    parsed.push({ ...data[key], id: key });
   });

   // Pegamos o array com o filtro e aplicamos a pesquisa
   if (availableSearchable.length > 0 && searchBy) {
    parsed = parsed.filter((item) => {
     for (let i = 0; i < availableSearchable.length; i++) {
      const { key, type } = availableSearchable[i];
      if (item[key] && item[key].toString().toLowerCase().includes(searchBy.toLowerCase()))
       return true;
     }
     return false;
    });
   }

   if (dateFilterKey && filterByDate.fromDate && filterByDate.toDate) {
    parsed = parsed.filter((item) => {
     if (!item[dateFilterKey]) return false;
     const date = new Date(item[dateFilterKey]);
     if (!isValid(date)) return false;
     if (
      (isAfter(date, filterByDate.fromDate) && isBefore(date, filterByDate.toDate)) ||
      isEqual(date, filterByDate.toDate) ||
      isEqual(date, filterByDate.fromDate)
     )
      return true;
    });
   }

   const defaultSort = systemConfig.list.defaultSort;
   if (defaultSort) {
    const { key, order } = defaultSort;

    // sort by the defaultSort
    parsed.sort((a, b) => {
     if (!a[key] && !b[key]) return 0;
     if (!a[key]) return 1;
     if (!b[key]) return -1;
     if (order === "asc") return a[key] > b[key] ? 1 : -1;
     return a[key] > b[key] ? -1 : 1;
    });
   } else
    parsed.sort((a, b) => {
     if (!a.createDate) return 1;
     if (!b.createDate) return -1;
     return a.createDate > b.createDate ? -1 : 1;
    });

   return parsed;
  }

  parse().then((parsed) => {
   setParsedData(parsed);
   setPage(1);
  });
 }, [data, filterBy, searchBy, availableSearchable, dateFilterKey, filterByDate]);

 useEffect(() => {
  if (parsedData.length >= (page - 1) * 20) {
   const paged = parsedData.slice(0, 20 * page);
   setPagedData(paged);
  }
 }, [page, parsedData]);

 // Gera o array de filtros e pesquisa
 useEffect(() => {
  const availableFilters = [];
  const searchableKeys = [];

  if (!cached) {
   Object.keys(systemConfig.fields).forEach((key) => {
    const field = systemConfig.fields[key];
    if (field.filterable) {
     availableFilters.push({
      label: field.label,
      key,
      options: field.options,
     });
    }
    if (field.searchable) searchableKeys.push({ key, type: field.type });
    if (field.isDateFilter) cachedDateKey = key;
   });

   cached = availableFilters;
   cachedSearchableKeys = searchableKeys;

   if (availableFilters.length > 0) {
    if (systemConfig?.saveFilterLocally) {
     console.log(systemConfig);
     try {
      getLocalStorage("filter").then((filter) => {
       const f = JSON.parse(filter);
       if (f.key && f.value && availableFilters.find((filter) => filter.key === f.key))
        setFilterBy(f);
      });
     } catch (e) {
      setFilterBy(
       systemConfig.list.baseFilter || {
        key: availableFilters[0].key,
        value: "_all_",
       }
      );
     }
    } else
     setFilterBy(
      systemConfig.list.baseFilter || {
       key: availableFilters[0].key,
       value: "_all_",
      }
     );
   }
  }

  setAvailableFilters(cached);
  setAvailableSearchable(cachedSearchableKeys);
  setDateFilterKey(cachedDateKey);
 }, [systemConfig, getLocalStorage]);

 const memoizedList = useMemo(() => {
  return (
   <FlatList
    style={{ backgroundColor: theme.colors.background, height: "100%" }}
    data={pagedData}
    onEndReachedThreshold={0.9}
    onEndReached={() => {
     setPage((i) => i + 1);
    }}
    renderItem={({ index, item }) => (
     <ListCard
      key={index}
      data={item}
      index={index}
      navigate={() => {
       navigation.navigate("Detalhes", {
        id: item.id,
       });
      }}
     />
    )}
   />
  );
 }, [navigation, theme, pagedData]);

 // cria o input de filtros
 const filterInput = useMemo(() => {
  if (!availableFilters || availableFilters.length === 0) return null;
  if (availableFilters.length === 1)
   return (
    <>
     <Menu
      visible={filterModal}
      onDismiss={() => setFilterModal(false)}
      anchor={
       <Appbar.Action
        icon={filterBy.value !== "_all_" ? "filter" : "filter-outline"}
        onPress={() => setFilterModal(true)}
       />
      }>
      <Menu.Item
       onPress={() => {
        setFilterBy({
         key: availableFilters[0].key,
         value: "_all_",
        });

        if (systemConfig?.saveFilterLocally)
         saveLocalStorage(
          "filter",
          JSON.stringify({ key: availableFilters[0].key, value: "_all_" })
         );

        setFilterModal(false);
       }}
       title="Todos"
      />
      {availableFilters[0].options.map((option, index) => (
       <Menu.Item
        key={index}
        onPress={() => {
         setFilterBy({
          key: availableFilters[0].key,
          value: option.value,
         });

         if (systemConfig?.saveFilterLocally) {
          saveLocalStorage(
           "filter",
           JSON.stringify({
            key: availableFilters[0].key,
            value: option.value,
           })
          );
         }

         setFilterModal(false);
        }}
        title={option.label}
       />
      ))}
     </Menu>
    </>
   );
  if (availableFilters.length > 1)
   return (
    <FilterModal
     availableFilters={availableFilters}
     setFilterBy={setFilterBy}
     filterBy={filterBy}
    />
   );
  return null;
 }, [filterBy, availableFilters, filterModal, saveLocalStorage, systemConfig]);

 const dateFilterInput = useMemo(() => {
  if (!dateFilterKey) return null;
  return (
   <DateFilterModal
    filterByDate={filterByDate}
    setFilterByDate={setFilterByDate}
    fieldLabel={systemConfig.fields[dateFilterKey].label}
   />
  );
 }, [dateFilterKey, filterByDate, systemConfig]);

 return (
  <View style={{ height: "100%" }}>
   <Appbar.Header>
    <Appbar.Content title={systemConfig.list.listTitle || "Lista"} />
    {dateFilterInput}
    {filterInput}
    <Appbar.Action icon="plus" onPress={() => navigation.navigate("Editar", { id: null })} />
   </Appbar.Header>
   <View
    style={{
     padding: 20,
     backgroundColor: theme.colors.background,
    }}>
    {availableSearchable.length > 0 && (
     <Searchbar
      style={{ maxWidth: 1000, marginHorizontal: "auto", width: "100%" }}
      onChangeText={(val) => setSearchBy(val)}
      defaultValue=""
      placeholder="Pesquisar"
     />
    )}
   </View>
   {isEmpty ? (
    <View
     style={{
      padding: 20,
      backgroundColor: theme.colors.background,
      height: "100%",
     }}>
     <ActivityIndicator style={{ marginTop: 30 }} animating size="large" />
    </View>
   ) : (
    memoizedList
   )}
  </View>
 );
}

function determineStatusColor(data, conf) {
 const { key, colors, defaultColor } = conf.list.status;
 const value = data[key];

 for (let i = 0; i < colors.length; i++) {
  const { color, operator, value: condition } = colors[i];
  if (operator === "=" && value === condition) {
   return color;
  }
 }

 return defaultColor;
}

function parseField(data, position, conf, users = {}) {
 const key = conf.list[position];
 const type = conf.fields[key].type;

 if (type === "list")
  return conf.fields[key].options.find(({ value }) => value === data[key])?.label;
 if (type === "user") return users?.[data[key]]?.email;
 if (type === "date") return data[key] ? new Date(data[key]).toLocaleDateString() : "";
 if (type === "datetime") return data[key] ? new Date(data[key]).toLocaleString() : "";
 return data[key];
}

export function ListCard({ data, index, navigate }) {
 const theme = useTheme();
 const { systemConfig: conf, users } = useDatabase();
 const color = useMemo(() => determineStatusColor(data, conf), [conf, data]);

 return (
  <View
   style={[
    styles.hstack,
    {
     backgroundColor: index % 2 === 0 ? theme.colors.background : theme.colors.secondaryContainer,
     maxWidth: 1000,
     marginHorizontal: "auto",
     width: "100%",
    },
   ]}>
   {conf.list.status && (
    <View style={{ minWidth: 10, background: color, backgroundColor: color }} />
   )}
   <TouchableRipple style={{ width: "100%", flex: 1 }} onPress={navigate}>
    <View style={{ marginHorizontal: 10, marginVertical: 10, flex: 1 }}>
     <View style={[styles.vstack]}>
      <Text variant="labelLarge">{data[conf.list.header]}</Text>
      {conf.list.subHeader && (
       <Text variant="labelMedium">{parseField(data, "subHeader", conf, users)}</Text>
      )}
     </View>
     {conf.list.body && (
      <Text
       variant="bodySmall"
       style={{
        marginTop: 15,
       }}>
       {parseField(data, "body", conf, users)}
       {/* {data[conf.list.body]} */}
      </Text>
     )}
     {conf.list.right && (
      <Text variant="labelMedium" style={{ marginLeft: "auto", marginRight: 10 }}>
       {parseField(data, "right", conf, users)}
      </Text>
     )}
    </View>
   </TouchableRipple>
  </View>
 );
}
